<template>
  <div>
    <div class="d-flex flex-row mb-6">
      <p class="text-2xl mb-6">Repeat with replacements</p>
      <v-spacer></v-spacer>
      <dev-menu></dev-menu>
    </div>
    <v-card class="container">
      <v-card-text>
        <div class="mt-6">
          <v-textarea
            outlined
            v-model="inputVars"
            name="input-vars"
            label="Values"
            value=""
          ></v-textarea>
          <v-textarea
            outlined
            v-model="inputToRepeat"
            name="input-to-process"
            label="Text to repeat with values"
            value="Hello $1 $2"
          ></v-textarea>
          <v-row>
            <v-col cols="2">
              <v-text-field
                v-model="separator"
                label="column separator"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="10">
              <v-btn @click="process" color="primary">
                Process
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div class="mt-6">
          <p>
            <pre class="d-inline-flex outline" >{{ result }}</pre>
          </p>
        </div>      
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import DevMenu from "@/views/menus/DevMenu.vue";

export default {
  name: "RepeatReplace",
  components: {DevMenu,},
  data() {
    return {
      separator:",",
      inputVars: `
Xavier Langlois,Jessica Alba
James Smith,Jane Doe
      `,
      inputToRepeat: `index:$$$i, counter:$$$c - We are happy to announce the wedding of $$$1 and $$$2`,
      result: "",
    }
  },
  methods: {
    process() {
      let finalResultStr = "";
      let lines = this.inputVars.split("\n");
      let valid_lines = [];
      for(let line of lines){
        line = line.trim();
        if(line != ""){
          valid_lines.push(line);
        }
      }
      for(let [i, line] of valid_lines.entries()){
        let repetitionStr = this.inputToRepeat;
        repetitionStr = repetitionStr.replaceAll("$$$i", i);
        repetitionStr = repetitionStr.replaceAll("$$$c", (i + 1));
        let values = line.split(this.separator);
        for(let [j, value] of values.entries()){
          let toReplaceStr = `$$$${(j + 1)}`;
          repetitionStr = repetitionStr.replaceAll(toReplaceStr, value.trim());
        }
        finalResultStr += repetitionStr + "\n\n";
      }
      this.result = finalResultStr;
    },
  },
  mounted() {
    this.process();
  },
  computed: {
    // myComputeFunction() {},
  },
  watch: {
    // isOpenCreateCronDialog(visible) {
    //   if (visible) {
    //     this.identifier = "";
    //     this.$refs.FormCron.resetValidation();
    //   }
    // },
  },
}
</script>
